/**
 * localStorage and sessionStorage are not available in all browsers (e.g. Safari in private mode).
 * Recently we run into a SecurityError issue in Safari when trying to access localStorage (CED-63242).
 * This is a wrapper around localStorage and sessionStorage that falls back to in-memory storage if the browser doesn't support the provided storage.
 * @param getStorage
 * @returns storage wrapper that falls back to in-memory storage if the browser doesn't support the provided storage
 */
export function storageFactory(getStorage: () => Storage): Storage {
    const inMemoryStorage: Map<string, string> = new Map();

    function isSupported() {
        const testKey = `storage-key-${Date.now()}`;
        try {
            getStorage().setItem(testKey, testKey);
            getStorage().removeItem(testKey);
            return true;
        } catch (e) {
            return false;
        }
    }

    function clear(): void {
        if (isSupported()) {
            getStorage().clear();
        } else {
            inMemoryStorage.clear();
        }
    }

    function getItem(name: string): string | null {
        if (isSupported()) {
            return getStorage().getItem(name);
        }
        if (inMemoryStorage.has(name)) {
            return inMemoryStorage.get(name) || null;
        }
        return null;
    }

    function key(index: number): string | null {
        if (isSupported()) {
            return getStorage().key(index);
        } else {
            const keys = Array.from(inMemoryStorage.keys());
            return keys[index] || null;
        }
    }

    function removeItem(name: string): void {
        if (isSupported()) {
            getStorage().removeItem(name);
        } else {
            inMemoryStorage.delete(name);
        }
    }

    function setItem(name: string, value: string): void {
        if (isSupported()) {
            getStorage().setItem(name, value);
        } else {
            inMemoryStorage.set(name, value);
        }
    }

    function length(): number {
        if (isSupported()) {
            return getStorage().length;
        } else {
            return inMemoryStorage.size;
        }
    }

    return {
        getItem,
        setItem,
        removeItem,
        clear,
        key,
        get length() {
            return length();
        },
    };
}
