import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import {
    UpdateCommunicationConsent,
    UpdateCommunicationConsentVariables,
} from "./__generated__/UpdateCommunicationConsent";

export const UPDATE_COMMUNICATION_CONSENT = gql`
    mutation UpdateCommunicationConsent(
        $userPublicId: String!
        $email: Boolean
        $paperMail: Boolean
        $textMessage: Boolean
        $paperlessEobs: Boolean
        $source: String
    ) {
        updateCommunicationConsent(
            userPublicId: $userPublicId
            email: $email
            paperMail: $paperMail
            textMessage: $textMessage
            paperlessEobs: $paperlessEobs
            source: $source
        ) {
            success
            errors
        }
    }
`;

type Props = {
    userPublicId: string;
    email?: boolean;
    paperMail?: boolean;
    textMessage?: boolean;
    paperlessEobs?: boolean;
    source?: string;
    onCompleted?: (data: UpdateCommunicationConsent) => void;
};

export function useUpdateCommunicationConsent(props?: Props) {
    const onCompleted = props?.onCompleted;
    const variables = {
        userPublicId: props?.userPublicId || "",
        email: props?.email,
        paperMail: props?.paperMail,
        textMessage: props?.textMessage,
        source: props?.source,
    };
    return useMutation<UpdateCommunicationConsent, UpdateCommunicationConsentVariables>(
        UPDATE_COMMUNICATION_CONSENT,
        {
            variables,
            onCompleted,
        },
    );
}
