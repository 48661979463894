import { getServerData, ServerDataKey, ServerDataType } from "../utils/getServerData";
import { useState } from "react";

/**
 * Pulls data from a server_data JSON script node on the page and returns it as a typed object.
 * Within the patient app, we have an unusual design where we are mostly server side rendered with Django,
 * with some React layered on top. In this context, we typically do not have APIs backing the data exchange from
 * the server to the client. So we use a pattern where we serialize data into a JSON script node in the HTML.
 *
 * The default naming patterns for such nodes is _server_data_{key} where {key} is the key passed to this hook.
 * This method abstracts around that pattern using idiomatic react design patterns so that when we eventually
 * refactor so that data is pulled from APIs, we'll need to do a minimum of restructuring.
 * @param key
 */
export const useServerData = <TKey extends ServerDataKey>(
    key: TKey,
): ServerDataType<TKey> | null => {
    const [data] = useState<ServerDataType<TKey> | null>(getServerData<TKey>(key));

    return data;
};
