import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import type {
    suppressPaperlessNudgeForCustomer,
    suppressPaperlessNudgeForCustomerVariables,
} from "src/hooks/mutations/__generated__/suppressPaperlessNudgeForCustomer";

export const SUPPRESS_PAPERLESS_NUDGE_FOR_CUSTOMER = gql`
    mutation suppressPaperlessNudgeForCustomer($userPublicId: String) {
        suppressPaperlessNudgeForCustomer(userPublicId: $userPublicId) {
            success
            errors
        }
    }
`;

type SuppressPaperlessNudgeForCustomerParams = {
    variables: suppressPaperlessNudgeForCustomerVariables;
};

export function useSuppressPaperlessNudgeForCustomer({
    variables,
}: SuppressPaperlessNudgeForCustomerParams) {
    return useMutation<
        suppressPaperlessNudgeForCustomer,
        suppressPaperlessNudgeForCustomerVariables
    >(SUPPRESS_PAPERLESS_NUDGE_FOR_CUSTOMER, {
        variables,
    });
}
