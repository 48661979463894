import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { GuestQuery, GuestQueryVariables } from "src/hooks/queries/__generated__/GuestQuery";

export const GUEST_QUERY = gql`
    query GuestQuery($userPublicId: String, $linkHash: String) {
        guest(userPublicId: $userPublicId, linkHash: $linkHash) {
            guestNotificationUrl
            userPublicId
            linkHash
        }
    }
`;

export const useGuestQuery = (queryOptions: QueryHookOptions<GuestQuery, GuestQueryVariables>) => {
    return useQuery<GuestQuery, GuestQueryVariables>(GUEST_QUERY, queryOptions);
};
