"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toCSSProps = exports.getWidthProp = exports.focusInputWithError = void 0;
/**
 * Turn an inline CSS property such as (width/height/maxWidth etc) into a style object that can be spread onto the style prop of a component.
 * @param value a number in pixel or a string with a unit (e.g. "100%", "10rem", "50px")
 * @returns a style object with propertyName as key if the value is not null or undefined, otherwise undefined
 */
const toCSSProps = (value, propertyName) => {
  if (value === null || value === undefined) {
    return undefined;
  }
  if (typeof value === "number" || /^[0-9]+(%|px|rem)$/.test(value)) {
    return {
      [propertyName]: value
    };
  }
  return undefined;
};

/**
 * Turn an inline width prop into a style object that can be spread onto the style prop of a component.
 * @param width a number in pixel or a string with a unit (e.g. "100%", "10rem", "50px")
 * @returns a style object with a width property if the width is not null or undefined, otherwise undefined
 */
exports.toCSSProps = toCSSProps;
const getWidthProp = width => {
  return toCSSProps(width, "width");
};

/**
 * focus the first input with error message in the form
 * @param form form element to search for error messages
 */
exports.getWidthProp = getWidthProp;
const focusInputWithError = form => {
  if (form) {
    const nodes = Array.from(form.querySelectorAll('input[aria-invalid="true"]'));
    if (nodes[0]) {
      nodes[0].focus();
    }
  }
};
exports.focusInputWithError = focusInputWithError;