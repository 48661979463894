import React from "react";

import { Toast } from "design_system/Toast";
import { Link } from "design_system/Link";

type toastProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    notificationCenterUrl: string;
};

export const SuccessToast = ({ open, setOpen, notificationCenterUrl }: toastProps) => {
    const onClose = () => {
        setOpen(false);
    };

    return (
        <Toast open={open} onClose={onClose} variant="success">
            <p>
                You’ve gone paperless! You can always change your notifications in{" "}
                <Link href={notificationCenterUrl}>My Account</Link>.
            </p>
        </Toast>
    );
};
