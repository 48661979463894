"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  SVG: true
};
Object.defineProperty(exports, "SVG", {
  enumerable: true,
  get: function () {
    return _SVG.SVG;
  }
});
var _reactFeather = require("react-feather");
Object.keys(_reactFeather).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _reactFeather[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _reactFeather[key];
    }
  });
});
var _SVG = require("./SVG");
var _Cedar = require("./logo/Cedar");
Object.keys(_Cedar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Cedar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Cedar[key];
    }
  });
});
var _Apple = require("./logo/Apple");
Object.keys(_Apple).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Apple[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Apple[key];
    }
  });
});
var _Google = require("./logo/Google");
Object.keys(_Google).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Google[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Google[key];
    }
  });
});
var _AmericanExpress = require("./logo/AmericanExpress");
Object.keys(_AmericanExpress).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _AmericanExpress[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _AmericanExpress[key];
    }
  });
});
var _Discover = require("./logo/Discover");
Object.keys(_Discover).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Discover[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Discover[key];
    }
  });
});
var _MasterCard = require("./logo/MasterCard");
Object.keys(_MasterCard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _MasterCard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _MasterCard[key];
    }
  });
});
var _Visa = require("./logo/Visa");
Object.keys(_Visa).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Visa[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Visa[key];
    }
  });
});
var _JCB = require("./logo/JCB");
Object.keys(_JCB).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _JCB[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _JCB[key];
    }
  });
});
var _DinersClub = require("./logo/DinersClub");
Object.keys(_DinersClub).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _DinersClub[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DinersClub[key];
    }
  });
});
var _SampleCheck = require("./logo/SampleCheck");
Object.keys(_SampleCheck).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _SampleCheck[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SampleCheck[key];
    }
  });
});
var _UnknownCC = require("./functional/UnknownCC");
Object.keys(_UnknownCC).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UnknownCC[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _UnknownCC[key];
    }
  });
});
var _Healthcare = require("./functional/Healthcare");
Object.keys(_Healthcare).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Healthcare[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Healthcare[key];
    }
  });
});
var _Spinner = require("./functional/Spinner");
Object.keys(_Spinner).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Spinner[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Spinner[key];
    }
  });
});
var _Throbber = require("./functional/Throbber");
Object.keys(_Throbber).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Throbber[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Throbber[key];
    }
  });
});
var _CheckCircle = require("./functional/CheckCircle2");
Object.keys(_CheckCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CheckCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CheckCircle[key];
    }
  });
});
var _CaretDown = require("./functional/CaretDown");
Object.keys(_CaretDown).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CaretDown[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CaretDown[key];
    }
  });
});
var _CaretLeft = require("./functional/CaretLeft");
Object.keys(_CaretLeft).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CaretLeft[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CaretLeft[key];
    }
  });
});
var _CaretRight = require("./functional/CaretRight");
Object.keys(_CaretRight).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CaretRight[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CaretRight[key];
    }
  });
});
var _CaretUp = require("./functional/CaretUp");
Object.keys(_CaretUp).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CaretUp[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CaretUp[key];
    }
  });
});
var _ChatBubble = require("./functional/ChatBubble");
Object.keys(_ChatBubble).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ChatBubble[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ChatBubble[key];
    }
  });
});
var _Lock = require("./functional/Lock2");
Object.keys(_Lock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Lock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Lock[key];
    }
  });
});
var _Help = require("./functional/Help2");
Object.keys(_Help).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Help[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Help[key];
    }
  });
});
var _DollarStack = require("./functional/DollarStack");
Object.keys(_DollarStack).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _DollarStack[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DollarStack[key];
    }
  });
});
var _BillGuarantee = require("./functional/BillGuarantee");
Object.keys(_BillGuarantee).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BillGuarantee[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _BillGuarantee[key];
    }
  });
});
var _Sparkle = require("./functional/Sparkle");
Object.keys(_Sparkle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Sparkle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Sparkle[key];
    }
  });
});
var _PiggyBank = require("./functional/PiggyBank");
Object.keys(_PiggyBank).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PiggyBank[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PiggyBank[key];
    }
  });
});
var _Bank = require("./functional/Bank");
Object.keys(_Bank).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Bank[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Bank[key];
    }
  });
});
var _HBA = require("./functional/HBA");
Object.keys(_HBA).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _HBA[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _HBA[key];
    }
  });
});
var _ShieldProtected = require("./functional/ShieldProtected");
Object.keys(_ShieldProtected).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ShieldProtected[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ShieldProtected[key];
    }
  });
});
var _PaymentPlan = require("./functional/PaymentPlan");
Object.keys(_PaymentPlan).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PaymentPlan[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PaymentPlan[key];
    }
  });
});
var _HospitalSimple = require("./functional/HospitalSimple");
Object.keys(_HospitalSimple).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _HospitalSimple[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _HospitalSimple[key];
    }
  });
});
var _Gear = require("./spot/Gear");
Object.keys(_Gear).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Gear[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Gear[key];
    }
  });
});
var _FaceHappy = require("./spot/FaceHappy");
Object.keys(_FaceHappy).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FaceHappy[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FaceHappy[key];
    }
  });
});
var _FaceSmile = require("./spot/FaceSmile");
Object.keys(_FaceSmile).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FaceSmile[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FaceSmile[key];
    }
  });
});
var _FaceGrin = require("./spot/FaceGrin");
Object.keys(_FaceGrin).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FaceGrin[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FaceGrin[key];
    }
  });
});
var _FaceMeh = require("./spot/FaceMeh");
Object.keys(_FaceMeh).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FaceMeh[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FaceMeh[key];
    }
  });
});
var _FaceUnamused = require("./spot/FaceUnamused");
Object.keys(_FaceUnamused).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FaceUnamused[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FaceUnamused[key];
    }
  });
});
var _FaceAngry = require("./spot/FaceAngry");
Object.keys(_FaceAngry).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FaceAngry[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FaceAngry[key];
    }
  });
});
var _Bell = require("./spot/Bell");
Object.keys(_Bell).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Bell[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Bell[key];
    }
  });
});
var _Globe = require("./spot/Globe");
Object.keys(_Globe).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Globe[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Globe[key];
    }
  });
});
var _IdCard = require("./spot/IdCard");
Object.keys(_IdCard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _IdCard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _IdCard[key];
    }
  });
});
var _Mail = require("./spot/Mail");
Object.keys(_Mail).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Mail[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Mail[key];
    }
  });
});
var _Calendar = require("./spot/Calendar");
Object.keys(_Calendar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Calendar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Calendar[key];
    }
  });
});
var _CalendarClock = require("./spot/CalendarClock");
Object.keys(_CalendarClock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CalendarClock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CalendarClock[key];
    }
  });
});
var _CheckCircle2 = require("./spot/CheckCircle");
Object.keys(_CheckCircle2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CheckCircle2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CheckCircle2[key];
    }
  });
});
var _CreditCard = require("./spot/CreditCard");
Object.keys(_CreditCard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CreditCard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CreditCard[key];
    }
  });
});
var _HeartHand = require("./spot/HeartHand");
Object.keys(_HeartHand).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _HeartHand[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _HeartHand[key];
    }
  });
});
var _Help2 = require("./spot/Help");
Object.keys(_Help2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Help2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Help2[key];
    }
  });
});
var _Message = require("./spot/Message");
Object.keys(_Message).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Message[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Message[key];
    }
  });
});
var _PhysicianStethoscope = require("./spot/PhysicianStethoscope");
Object.keys(_PhysicianStethoscope).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PhysicianStethoscope[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PhysicianStethoscope[key];
    }
  });
});
var _Activity = require("./spot/Activity");
Object.keys(_Activity).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Activity[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Activity[key];
    }
  });
});
var _AlertCircle = require("./spot/AlertCircle");
Object.keys(_AlertCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _AlertCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _AlertCircle[key];
    }
  });
});
var _AlertOctagon = require("./spot/AlertOctagon");
Object.keys(_AlertOctagon).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _AlertOctagon[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _AlertOctagon[key];
    }
  });
});
var _AlertTriangle = require("./spot/AlertTriangle");
Object.keys(_AlertTriangle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _AlertTriangle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _AlertTriangle[key];
    }
  });
});
var _Clock = require("./spot/Clock");
Object.keys(_Clock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Clock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Clock[key];
    }
  });
});
var _Anchor = require("./spot/Anchor");
Object.keys(_Anchor).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Anchor[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Anchor[key];
    }
  });
});
var _Archive = require("./spot/Archive");
Object.keys(_Archive).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Archive[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Archive[key];
    }
  });
});
var _ArrowDownCircle = require("./spot/ArrowDownCircle");
Object.keys(_ArrowDownCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ArrowDownCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ArrowDownCircle[key];
    }
  });
});
var _ArrowLeftCircle = require("./spot/ArrowLeftCircle");
Object.keys(_ArrowLeftCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ArrowLeftCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ArrowLeftCircle[key];
    }
  });
});
var _ArrowRightCircle = require("./spot/ArrowRightCircle");
Object.keys(_ArrowRightCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ArrowRightCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ArrowRightCircle[key];
    }
  });
});
var _ArrowUpCircle = require("./spot/ArrowUpCircle");
Object.keys(_ArrowUpCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ArrowUpCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ArrowUpCircle[key];
    }
  });
});
var _AtSign = require("./spot/AtSign");
Object.keys(_AtSign).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _AtSign[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _AtSign[key];
    }
  });
});
var _CrossHair = require("./spot/CrossHair");
Object.keys(_CrossHair).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CrossHair[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CrossHair[key];
    }
  });
});
var _Disc = require("./spot/Disc");
Object.keys(_Disc).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Disc[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Disc[key];
    }
  });
});
var _FolderMinus = require("./spot/FolderMinus");
Object.keys(_FolderMinus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FolderMinus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FolderMinus[key];
    }
  });
});
var _PhoneForwarded = require("./spot/PhoneForwarded");
Object.keys(_PhoneForwarded).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PhoneForwarded[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PhoneForwarded[key];
    }
  });
});
var _PiggyBank2 = require("./spot/PiggyBank");
Object.keys(_PiggyBank2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PiggyBank2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PiggyBank2[key];
    }
  });
});
var _StackOfCoins = require("./spot/StackOfCoins");
Object.keys(_StackOfCoins).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _StackOfCoins[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _StackOfCoins[key];
    }
  });
});
var _Tools = require("./spot/Tools");
Object.keys(_Tools).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Tools[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Tools[key];
    }
  });
});
var _Trash = require("./spot/Trash");
Object.keys(_Trash).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Trash[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Trash[key];
    }
  });
});
var _Truck = require("./spot/Truck");
Object.keys(_Truck).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Truck[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Truck[key];
    }
  });
});
var _Umbrella = require("./spot/Umbrella");
Object.keys(_Umbrella).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Umbrella[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Umbrella[key];
    }
  });
});
var _UploadCloud = require("./spot/UploadCloud");
Object.keys(_UploadCloud).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UploadCloud[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _UploadCloud[key];
    }
  });
});
var _UserCheck = require("./spot/UserCheck");
Object.keys(_UserCheck).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UserCheck[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _UserCheck[key];
    }
  });
});
var _UserFrontDesk = require("./spot/UserFrontDesk");
Object.keys(_UserFrontDesk).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UserFrontDesk[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _UserFrontDesk[key];
    }
  });
});
var _UserMinus = require("./spot/UserMinus");
Object.keys(_UserMinus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UserMinus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _UserMinus[key];
    }
  });
});
var _UserPlus = require("./spot/UserPlus");
Object.keys(_UserPlus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UserPlus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _UserPlus[key];
    }
  });
});
var _UserX = require("./spot/UserX");
Object.keys(_UserX).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _UserX[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _UserX[key];
    }
  });
});
var _Video = require("./spot/Video");
Object.keys(_Video).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Video[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Video[key];
    }
  });
});
var _VideoOff = require("./spot/VideoOff");
Object.keys(_VideoOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _VideoOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _VideoOff[key];
    }
  });
});
var _VoiceMail = require("./spot/VoiceMail");
Object.keys(_VoiceMail).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _VoiceMail[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _VoiceMail[key];
    }
  });
});
var _Watch = require("./spot/Watch");
Object.keys(_Watch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Watch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Watch[key];
    }
  });
});
var _Wind = require("./spot/Wind");
Object.keys(_Wind).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Wind[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Wind[key];
    }
  });
});
var _XCircle = require("./spot/XCircle");
Object.keys(_XCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _XCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _XCircle[key];
    }
  });
});
var _XOctagon = require("./spot/XOctagon");
Object.keys(_XOctagon).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _XOctagon[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _XOctagon[key];
    }
  });
});
var _XSquare = require("./spot/XSquare");
Object.keys(_XSquare).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _XSquare[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _XSquare[key];
    }
  });
});
var _ZapOff = require("./spot/ZapOff");
Object.keys(_ZapOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ZapOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ZapOff[key];
    }
  });
});
var _BarChart = require("./spot/BarChart");
Object.keys(_BarChart).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BarChart[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _BarChart[key];
    }
  });
});
var _Battery = require("./spot/Battery");
Object.keys(_Battery).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Battery[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Battery[key];
    }
  });
});
var _Divide = require("./spot/Divide");
Object.keys(_Divide).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Divide[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Divide[key];
    }
  });
});
var _Eye = require("./spot/Eye");
Object.keys(_Eye).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Eye[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Eye[key];
    }
  });
});
var _EyeOff = require("./spot/EyeOff");
Object.keys(_EyeOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _EyeOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _EyeOff[key];
    }
  });
});
var _Headphones = require("./spot/Headphones");
Object.keys(_Headphones).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Headphones[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Headphones[key];
    }
  });
});
var _Lock2 = require("./spot/Lock");
Object.keys(_Lock2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Lock2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Lock2[key];
    }
  });
});
var _MapPin = require("./spot/MapPin");
Object.keys(_MapPin).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _MapPin[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _MapPin[key];
    }
  });
});
var _PhoneHome = require("./spot/PhoneHome");
Object.keys(_PhoneHome).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PhoneHome[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PhoneHome[key];
    }
  });
});
var _PhoneMessage = require("./spot/PhoneMessage");
Object.keys(_PhoneMessage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PhoneMessage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PhoneMessage[key];
    }
  });
});
var _PhoneMissed = require("./spot/PhoneMissed");
Object.keys(_PhoneMissed).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PhoneMissed[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PhoneMissed[key];
    }
  });
});
var _PhoneOff = require("./spot/PhoneOff");
Object.keys(_PhoneOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PhoneOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PhoneOff[key];
    }
  });
});
var _Send = require("./spot/Send");
Object.keys(_Send).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Send[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Send[key];
    }
  });
});
var _SendAirplane = require("./spot/SendAirplane");
Object.keys(_SendAirplane).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _SendAirplane[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SendAirplane[key];
    }
  });
});
var _ShieldOff = require("./spot/ShieldOff");
Object.keys(_ShieldOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ShieldOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ShieldOff[key];
    }
  });
});
var _Sliders = require("./spot/Sliders");
Object.keys(_Sliders).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Sliders[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Sliders[key];
    }
  });
});
var _Stars = require("./spot/Stars");
Object.keys(_Stars).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Stars[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Stars[key];
    }
  });
});
var _ThumbsDown = require("./spot/ThumbsDown");
Object.keys(_ThumbsDown).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ThumbsDown[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ThumbsDown[key];
    }
  });
});
var _ThumbsUp = require("./spot/ThumbsUp");
Object.keys(_ThumbsUp).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ThumbsUp[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ThumbsUp[key];
    }
  });
});
var _Hourglass = require("./spot/Hourglass");
Object.keys(_Hourglass).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Hourglass[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Hourglass[key];
    }
  });
});
var _Award = require("./spot/Award");
Object.keys(_Award).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Award[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Award[key];
    }
  });
});
var _Bank2 = require("./spot/Bank");
Object.keys(_Bank2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Bank2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Bank2[key];
    }
  });
});
var _BatteryCharging = require("./spot/BatteryCharging");
Object.keys(_BatteryCharging).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BatteryCharging[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _BatteryCharging[key];
    }
  });
});
var _BellOff = require("./spot/BellOff");
Object.keys(_BellOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BellOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _BellOff[key];
    }
  });
});
var _BillLock = require("./spot/BillLock");
Object.keys(_BillLock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BillLock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _BillLock[key];
    }
  });
});
var _Bills = require("./spot/Bills");
Object.keys(_Bills).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Bills[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Bills[key];
    }
  });
});
var _BillGuarantee2 = require("./spot/BillGuarantee");
Object.keys(_BillGuarantee2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BillGuarantee2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _BillGuarantee2[key];
    }
  });
});
var _Book = require("./spot/Book");
Object.keys(_Book).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Book[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Book[key];
    }
  });
});
var _BookOpen = require("./spot/BookOpen");
Object.keys(_BookOpen).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _BookOpen[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _BookOpen[key];
    }
  });
});
var _Bookmark = require("./spot/Bookmark");
Object.keys(_Bookmark).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Bookmark[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Bookmark[key];
    }
  });
});
var _Box = require("./spot/Box");
Object.keys(_Box).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Box[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Box[key];
    }
  });
});
var _Briefcase = require("./spot/Briefcase");
Object.keys(_Briefcase).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Briefcase[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Briefcase[key];
    }
  });
});
var _CalendarSelected = require("./spot/CalendarSelected");
Object.keys(_CalendarSelected).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CalendarSelected[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CalendarSelected[key];
    }
  });
});
var _Camera = require("./spot/Camera");
Object.keys(_Camera).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Camera[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Camera[key];
    }
  });
});
var _CameraOff = require("./spot/CameraOff");
Object.keys(_CameraOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CameraOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CameraOff[key];
    }
  });
});
var _CardHealth = require("./spot/CardHealth");
Object.keys(_CardHealth).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CardHealth[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CardHealth[key];
    }
  });
});
var _CheckSquare = require("./spot/CheckSquare");
Object.keys(_CheckSquare).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _CheckSquare[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _CheckSquare[key];
    }
  });
});
var _Chrome = require("./spot/Chrome");
Object.keys(_Chrome).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Chrome[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Chrome[key];
    }
  });
});
var _Clipboard = require("./spot/Clipboard");
Object.keys(_Clipboard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Clipboard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Clipboard[key];
    }
  });
});
var _Clock2 = require("./spot/Clock2");
Object.keys(_Clock2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Clock2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Clock2[key];
    }
  });
});
var _Code = require("./spot/Code");
Object.keys(_Code).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Code[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Code[key];
    }
  });
});
var _Coffee = require("./spot/Coffee");
Object.keys(_Coffee).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Coffee[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Coffee[key];
    }
  });
});
var _Compass = require("./spot/Compass");
Object.keys(_Compass).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Compass[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Compass[key];
    }
  });
});
var _Copy = require("./spot/Copy");
Object.keys(_Copy).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Copy[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Copy[key];
    }
  });
});
var _Crop = require("./spot/Crop");
Object.keys(_Crop).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Crop[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Crop[key];
    }
  });
});
var _Cross = require("./spot/Cross");
Object.keys(_Cross).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Cross[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Cross[key];
    }
  });
});
var _Delete = require("./spot/Delete");
Object.keys(_Delete).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Delete[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Delete[key];
    }
  });
});
var _Device = require("./spot/Device");
Object.keys(_Device).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Device[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Device[key];
    }
  });
});
var _Dollar = require("./spot/Dollar");
Object.keys(_Dollar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Dollar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Dollar[key];
    }
  });
});
var _DownloadCloud = require("./spot/DownloadCloud");
Object.keys(_DownloadCloud).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _DownloadCloud[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DownloadCloud[key];
    }
  });
});
var _Download = require("./spot/Download");
Object.keys(_Download).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Download[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Download[key];
    }
  });
});
var _Droplet = require("./spot/Droplet");
Object.keys(_Droplet).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Droplet[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Droplet[key];
    }
  });
});
var _Edit = require("./spot/Edit");
Object.keys(_Edit).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Edit[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Edit[key];
    }
  });
});
var _ExternalLink = require("./spot/ExternalLink");
Object.keys(_ExternalLink).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ExternalLink[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ExternalLink[key];
    }
  });
});
var _FileText = require("./spot/FileText");
Object.keys(_FileText).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _FileText[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _FileText[key];
    }
  });
});
var _Filter = require("./spot/Filter");
Object.keys(_Filter).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Filter[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Filter[key];
    }
  });
});
var _HeartCross = require("./spot/HeartCross");
Object.keys(_HeartCross).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _HeartCross[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _HeartCross[key];
    }
  });
});
var _Heart = require("./spot/Heart");
Object.keys(_Heart).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Heart[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Heart[key];
    }
  });
});
var _HelpCircle = require("./spot/HelpCircle");
Object.keys(_HelpCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _HelpCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _HelpCircle[key];
    }
  });
});
var _Home = require("./spot/Home");
Object.keys(_Home).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Home[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Home[key];
    }
  });
});
var _Hospital = require("./spot/Hospital");
Object.keys(_Hospital).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Hospital[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Hospital[key];
    }
  });
});
var _Image = require("./spot/Image");
Object.keys(_Image).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Image[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Image[key];
    }
  });
});
var _Inbox = require("./spot/Inbox");
Object.keys(_Inbox).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Inbox[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Inbox[key];
    }
  });
});
var _Info = require("./spot/Info");
Object.keys(_Info).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Info[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Info[key];
    }
  });
});
var _Key = require("./spot/Key");
Object.keys(_Key).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Key[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Key[key];
    }
  });
});
var _Layers = require("./spot/Layers");
Object.keys(_Layers).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Layers[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Layers[key];
    }
  });
});
var _Layout = require("./spot/Layout");
Object.keys(_Layout).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Layout[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Layout[key];
    }
  });
});
var _Leaf = require("./spot/Leaf");
Object.keys(_Leaf).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Leaf[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Leaf[key];
    }
  });
});
var _LifeBuoy = require("./spot/LifeBuoy");
Object.keys(_LifeBuoy).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _LifeBuoy[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _LifeBuoy[key];
    }
  });
});
var _Link = require("./spot/Link");
Object.keys(_Link).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Link[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Link[key];
    }
  });
});
var _Login = require("./spot/Login");
Object.keys(_Login).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Login[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Login[key];
    }
  });
});
var _Logout = require("./spot/Logout");
Object.keys(_Logout).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Logout[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Logout[key];
    }
  });
});
var _Map = require("./spot/Map");
Object.keys(_Map).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Map[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Map[key];
    }
  });
});
var _Mask = require("./spot/Mask");
Object.keys(_Mask).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Mask[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Mask[key];
    }
  });
});
var _Maximize = require("./spot/Maximize");
Object.keys(_Maximize).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Maximize[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Maximize[key];
    }
  });
});
var _Maximize2 = require("./spot/Maximize2");
Object.keys(_Maximize2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Maximize2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Maximize2[key];
    }
  });
});
var _MoneyLoad = require("./spot/MoneyLoad");
Object.keys(_MoneyLoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _MoneyLoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _MoneyLoad[key];
    }
  });
});
var _MicOff = require("./spot/MicOff");
Object.keys(_MicOff).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _MicOff[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _MicOff[key];
    }
  });
});
var _Mic = require("./spot/Mic");
Object.keys(_Mic).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Mic[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Mic[key];
    }
  });
});
var _Physician = require("./spot/Physician");
Object.keys(_Physician).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Physician[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Physician[key];
    }
  });
});
var _PlayCircle = require("./spot/PlayCircle");
Object.keys(_PlayCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PlayCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PlayCircle[key];
    }
  });
});
var _PlusCircle = require("./spot/PlusCircle");
Object.keys(_PlusCircle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _PlusCircle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _PlusCircle[key];
    }
  });
});
var _Power = require("./spot/Power");
Object.keys(_Power).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Power[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Power[key];
    }
  });
});
var _Printer = require("./spot/Printer");
Object.keys(_Printer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Printer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Printer[key];
    }
  });
});
var _Save = require("./spot/Save");
Object.keys(_Save).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Save[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Save[key];
    }
  });
});
var _DollarStack2 = require("./spot/DollarStack");
Object.keys(_DollarStack2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _DollarStack2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _DollarStack2[key];
    }
  });
});
var _Tag = require("./spot/Tag");
Object.keys(_Tag).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Tag[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Tag[key];
    }
  });
});
var _TrendingDown = require("./spot/TrendingDown");
Object.keys(_TrendingDown).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TrendingDown[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _TrendingDown[key];
    }
  });
});
var _TrendingUp = require("./spot/TrendingUp");
Object.keys(_TrendingUp).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TrendingUp[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _TrendingUp[key];
    }
  });
});
var _SpotUser = require("./spot/SpotUser");
Object.keys(_SpotUser).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _SpotUser[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SpotUser[key];
    }
  });
});
var _SpotList = require("./spot/SpotList");
Object.keys(_SpotList).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _SpotList[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SpotList[key];
    }
  });
});
var _SpotPhoneCall = require("./spot/SpotPhoneCall");
Object.keys(_SpotPhoneCall).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _SpotPhoneCall[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SpotPhoneCall[key];
    }
  });
});
var _SpotCompleteLoad = require("./spot/SpotCompleteLoad");
Object.keys(_SpotCompleteLoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _SpotCompleteLoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _SpotCompleteLoad[key];
    }
  });
});