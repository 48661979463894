"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Box = require("./Box");
Object.keys(_Box).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Box[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Box[key];
    }
  });
});
var _Panel = require("./Panel");
Object.keys(_Panel).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Panel[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Panel[key];
    }
  });
});
var _Flex = require("./Flex");
Object.keys(_Flex).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Flex[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Flex[key];
    }
  });
});
var _Stack = require("./Stack");
Object.keys(_Stack).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Stack[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Stack[key];
    }
  });
});
var _Divider = require("./Divider");
Object.keys(_Divider).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Divider[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Divider[key];
    }
  });
});