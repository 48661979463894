/** We have an unusual design where we are mostly server side rendered with Django,
 * with some React layered on top. In this context, we typically do not have APIs backing the data exchange from the server to
 * the client. So we use a pattern where we serialize data into a JSON script node in the HTML.
 * The default naming patterns for such nodes is _server_data_{key} where {key} is the key passed to this hook.
 * This hook abstracts around that pattern using idiomatic react design patterns so that when we eventually refactor
 * so that data is pulled from APIs, we'll need to do a minimum of restructuring.
 */
import {
    ServerDataGlobal,
    ServerDataGuestGoPaperlessModal,
    ServerDataHome,
    ServerDataModal,
    ServerDataVerify,
    ServerDataInsuranceTracker,
} from "src/ds__types";

export type ServerDataKey =
    | "modal"
    | "global"
    | "date_picker"
    | "payment_survey"
    | "checkout"
    | "notification_center"
    | "verify"
    | "visit_details"
    | "home"
    | "welcome_v2"
    | "guest_go_paperless_modal"
    | "insurance_tracker";

/**
 * This is some heavy Typescript - so let's walk through it
 * Depending on which server data we are pulling, the data included on the JSON node will
 * vary. We could just leave it an untyped Record<string, any>, but that would require a lot
 * of unsafe casts or use of any. Since we already have some of these types defined in ds__types we can use
 * conditional types to map the key to the correct type. For ones without existing types
 * We aren't going back and backfilling them right now.
 *
 * If you add a new server data key, please go in and add or update the corresponding type in ds__types.ts
 * and if necessary, update the conditional type below.
 */
export type ServerDataType<TKey extends ServerDataKey> = TKey extends "global"
    ? ServerDataGlobal
    : TKey extends "home"
    ? ServerDataHome
    : TKey extends "verify"
    ? ServerDataVerify
    : TKey extends "guest_go_paperless_modal"
    ? ServerDataGuestGoPaperlessModal
    : TKey extends "modal"
    ? ServerDataModal
    : TKey extends "insurance_tracker"
    ? ServerDataInsuranceTracker
    : Record<string, any>;

const readJsonDataFromScriptNode = <T>(id: string): T | null => {
    const scriptNode = $(`script#${id}[type="application/json"]`);
    if (scriptNode.length === 0) {
        return null;
    }
    const data = JSON.parse(scriptNode.text());
    return data as T;
};

/**
 * Pulls JSON data from a JSON script tag as a typed object
 * The type of the returned object is determined by the key passed in
 * We use this mechanism of JSON script tags in Django views to pass data from the server
 * to the client in contexts where we want to have some React or JS on top of an
 * existing Django view rather than a wholesale replacement
 *
 * @param key the key of the server data to pull
 */
export const getServerData = <TKey extends ServerDataKey>(
    key: TKey,
): ServerDataType<TKey> | null => {
    const nodeId = `_server_data_${key}`;
    return readJsonDataFromScriptNode<ServerDataType<TKey>>(nodeId);
};
