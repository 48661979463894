import React from "react";
import ReactDOM from "react-dom";
import { WelcomeBanner } from "./components/banner/WelcomeBanner";
import { storageFactory } from "./utils/storageFactory";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./utils/apollo";
import { GuestGoPaperlessModal } from "./components/go_paperless/GuestGoPaperlessModal";

(async () => {
    const response: any = await fetch("/api/patient/configuration-editor/");
    if (response.ok) {
        const data: any = await response.json();
        const welcomeBanner = data?.welcomeBanner;
        const WELCOME_BANNER_KEY = `welcome banner key: ${data?.provider} : ${data?.pbu}`;
        const sessionStore = storageFactory(() => sessionStorage);
        const showBanner = sessionStore.getItem(WELCOME_BANNER_KEY) !== "true";
        const otherBannerIsDisplayed = $("._banner_above_nav:visible").length > 0;

        if (welcomeBanner?.body && showBanner && !otherBannerIsDisplayed) {
            if ($(".welcome-banner").length === 0) {
                $('<div class="welcome-banner"/>').prependTo($("header > .fixed"));
            }
            const container = $("header > .fixed .welcome-banner").get(0) as HTMLElement;
            const {
                takeAction,
                title,
                body,
                style,
                showIcon,
                iconName,
                linkInternal,
                urlParam,
                isSearchParam,
                searchParamValue,
            } = welcomeBanner;
            ReactDOM.render(
                <ApolloProvider client={apolloClient}>
                    <WelcomeBanner
                        takeAction={takeAction}
                        title={title}
                        content={body}
                        bannerStyle={style}
                        showIcon={showIcon}
                        iconName={iconName}
                        linkInternal={linkInternal}
                        urlParam={urlParam}
                        isSearchParam={isSearchParam}
                        searchParamValue={searchParamValue}
                        onClose={() => {
                            sessionStore.setItem(WELCOME_BANNER_KEY, "true");
                            $(".invisible .welcome-banner").remove();
                        }}
                    />
                </ApolloProvider>,
                container,
            );
            //need to clone welcome banner to invisible to position main content properly
            $(".welcome-banner").clone().prependTo($("body > .invisible"));
        }
    }

    if ($("#guest-go-paperless-modal").length === 0) {
        $('<div id="guest-go-paperless-modal"/>').prependTo($("main"));
    }
    const container = $("main #guest-go-paperless-modal").get(0) as HTMLElement;
    ReactDOM.render(
        <ApolloProvider client={apolloClient}>
            <GuestGoPaperlessModal />
        </ApolloProvider>,
        container,
    );
})();
