import React, { useState } from "react";
import { SpotGear, SpotInfo, Leaf } from "design_system/Icon";
import classNames from "classnames";
import { Button } from "design_system/Button";
import { useSuppressPaperlessNudgeForCustomer } from "../../hooks/mutations/useSuppressPaperlessNudgeForCustomer";

type BannerStyle = "gray" | "blue";
type Props = {
    bannerStyle?: BannerStyle;
    content: string;
    isSearchParam: boolean;
    linkInternal?: boolean;
    onClose?: () => void;
    searchParamValue: string;
    showIcon?: boolean;
    iconName?: string;
    takeAction: boolean;
    title?: string;
    urlParam?: string;
};

const BLUE = "blue";
const GRAY = "gray";
const ICON = {
    INFO: "info",
    LEAF: "leaf",
};

export const WelcomeBanner = ({
    bannerStyle = BLUE,
    content,
    isSearchParam = false,
    linkInternal,
    onClose,
    searchParamValue = "",
    showIcon = true,
    iconName = ICON.INFO,
    takeAction = false,
    title,
    urlParam = "",
}: Props) => {
    const { user_public_id: userPublicId } = cedar.serverDataGlobal;
    const [visible, setVisible] = useState(true);
    const [suppressPaperlessNudgeForCustomer] = useSuppressPaperlessNudgeForCustomer({
        variables: { userPublicId },
    });

    const url = new URL(window.location.href);

    const takeActionLaterVariant = bannerStyle === BLUE ? "primary" : "tertiary";

    const getUrl = () => {
        if (linkInternal && isSearchParam) {
            url.searchParams.append(urlParam, searchParamValue);
            return url.href;
        }
        return urlParam;
    };

    const renderGoPaperlessAction = () => {
        return (
            <div className="ml-auto mt-10">
                <Button
                    className={classNames("pr-28 pl-28 mr-10", {
                        "text-white": bannerStyle === BLUE,
                    })}
                    data-testid="paperless-take-action-later-btn"
                    variant={takeActionLaterVariant}
                    onClick={async () => {
                        setVisible(false);
                        await suppressPaperlessNudgeForCustomer();
                        onClose?.();
                    }}
                >
                    {gettext("I'll do it later")}
                </Button>
                <a
                    className={classNames("btn btn-small border-gray-e7e9ef border-r-2 px-16", {
                        "btn-secondary ": bannerStyle !== BLUE,
                    })}
                    data-patient-app-click="go_paperless_btn"
                    href={getUrl()}
                >
                    {gettext("Go Paperless")}
                </a>
            </div>
        );
    };

    if (visible) {
        return (
            <div
                className={classNames({
                    "bg-primary-3667e9 text-white": bannerStyle === BLUE,
                    "bg-gray-f4f5f7 text-black": bannerStyle === GRAY,
                })}
            >
                <div className="max-w-1100 m-auto p-16">
                    <div className="flex">
                        <div className="w-11/12">
                            <div className="flex flex-col">
                                <div className="welcome-banner-container flex">
                                    <div className="w-auto mr-22 py-12">
                                        {showIcon &&
                                            (bannerStyle === BLUE ? (
                                                <SpotGear size={40} />
                                            ) : (
                                                <>
                                                    {iconName == ICON.INFO && (
                                                        <SpotInfo
                                                            strokeWidth="0.5"
                                                            spotColor="#F4F5F7"
                                                            color="#65657B"
                                                            size={40}
                                                        />
                                                    )}
                                                    {iconName == ICON.LEAF && (
                                                        <Leaf
                                                            size={40}
                                                            spotColor="#C9DBFE"
                                                            strokeWidth="0.5"
                                                        />
                                                    )}
                                                </>
                                            ))}
                                    </div>
                                    <div className="flex flex-col justify-center w-auto">
                                        <div className="text-1_25">
                                            <strong>{title}</strong>
                                        </div>
                                        <div
                                            className="text-1 welcome-banner-body"
                                            dangerouslySetInnerHTML={{ __html: content }}
                                        ></div>
                                    </div>
                                </div>
                                {takeAction && renderGoPaperlessAction()}
                            </div>
                        </div>
                        <div className="w-1/12 pr-8 text-right">
                            <button
                                className="text-1"
                                data-testid="close-welcome-banner-btn"
                                onClick={async () => {
                                    setVisible(false);
                                    // currently, only paperless welcome banner should have takeAction
                                    if (takeAction) {
                                        await suppressPaperlessNudgeForCustomer();
                                    }
                                    onClose?.();
                                }}
                            >
                                &#x2715;
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};
